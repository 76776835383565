import React, { useState, useContext, useRef, useEffect, useCallback } from "react";
import { ASSETS_QUERY } from "../../apollo/queries/assetsQuery";
import Typography from "../../styled/Typography/Typography";
import { useQuery } from "@apollo/client";
import { GET_TEAMS_BY_OWNER } from "../../apollo/queries/getTeamsByOwner";
import { parseCurrency } from '../../utilities';
import SubscribeButton from "./SubscribeButton";
import FilterText from "./FilterText";
import LawfirmCell from "./LawfirmCell";
import TeamCell from "./TeamCell";
import DocumentsButton from "./DocumentsButton";
import ImportAssets from "../Modals/ImportAssets";
import Button from "../../styled/Button/Button";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import StandardGrid from "../Grid/Grid";
import StageBars from './StageBars';
import ColoredCell from './ColoredCell';
import TableSkeleton from "../Skeleton/TableSkeleton";
import { UserContext } from "../../context/UserContext";
import AssignAssetToTeam from "../Modals/AssignAssetToTeam";
import ReferAssetToLawFirm from "../Modals/ReferAssetToLawFirm";
import styled from "@emotion/styled";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useTheme } from "@emotion/react";
import { GET_USER_WATCHLIST } from "../../apollo/queries/getUserWatchlist";
import ColoredDot from "../ColoredDot/ColoredDot";
import UpdateAssetWatchlist from "../Modals/UpdateAssetWatchlist";
import APIUpdate from "./APIUpdate";

const Floating = styled('div')`
  position: absolute;
  bottom: 120px;
  right: 20px;
`;

const frameworkComponents = {
  SubscribeButton,
  FilterText,
  LawfirmCell,
  DocumentsButton,
  StageBars,
  TeamCell,
  ColoredCell
};

const ls = window.localStorage;

const valueFormatter = params => {
  switch (params.colDef.field) {
  case ("referredLawFirm"):
    if (!params.value) {
      return "No Referral"
    } else {
      return params.value
    }
  case ("assignedTeams"): 
    if (!params.value) {
      return "No Team"
    } else {
      return params.value
    }
  case ("activeStatus"): 
    if (!params.value) {
      return "No Active Process"
    } else {
      return params.value
    }
  case ("statusValue"): 
    if (!params.value) {
      return "No Active Process"
    } else {
      return params.value
    }
  case ("latestComment"): 
    if (!params.value) {
      return "No Comments"
    } else {
      return params.value
    }
  case ("borrowerName"): 
    if (!params.value) {
      return "No Borrower Name"
    } else {
      return params.value
    }
  case ("assetAddress"): 
    if (!params.value) {
      return "No Address"
    } else {
      return params.value
    }
  }
  
}

export default function AssetList({ client, selectedTab, lastApiImportDate, shouldShowRefreshButton }) {
  const gridApiRef = useRef(null);
  const { user, setUser, portfolioID } = useContext(UserContext);
  const theme = useTheme();

  const [limit] = useState(-1);
  const [watchedAssets, setWatchedAssets] = useState([]);
  const [items, setItems] = useState([]);
  const [selectedAssets, setSelectedAssets] = useState([]);
  const [actionButtonAnchor, setActionButtonAnchor] = useState(null);
  const [showTeamReferral, setShowTeamReferral] = useState(false);
  const [showLawfirmReferral, setShowLawfirmReferral] = useState(false);
  const [showImportAssetsModalFirst, setShowImportAssetsModalFirst] = useState(true);
  const [showImportAssetsModal, setShowImportAssetsModal] = useState(false);
  const [showUpdateWatchlist, setShowUpdateWatchlist] = useState(false);
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Loan #",
      field: "loanNumber",
      minWidth: 220,
      cellRenderer: "agGroupCellRenderer",
      cellRendererParams: {
        innerRenderer: (params) => {
          return params.data?.watched
            ? <span style={{
              display: 'flex',
              alignItems: 'center',
            }}><VisibilityIcon titleAccess="Watched" style={{marginRight: '10px', color: theme.themeColor.brandPrimaryBlue}}/>{params.value}</span>
            : <span>{params.value}</span>
        },
      },
      headerCheckboxSelection: true,
      checkboxSelection: (params) => !params.node.group,
      suppressColumnsToolPanel: true,
      sortable: true,
      sort: 'asc'
    },
    {
      headerName: "Property ID",
      field: "propertyID",
      sortable: true,
      filter: true,
      width: 400,
      cellStyle: { fontFamily: 'IBM Plex Mono' },
    },
    {
      headerName: "Cross Collaterized",
      field: "isCC",
      sortable: true,
      filter: true,
      width: 175,
      cellRenderer: (params) => {
        return params.data?.isCC
          ? <span style={{
            display: 'flex',
            alignItems: 'center',
          }}><ColoredDot color="green" /></span>
          : <span style={{
            display: 'flex',
            alignItems: 'center',
          }}><ColoredDot /></span>
      },
    },
    {
      headerName: "Watched",
      field: "watched",
      sortable: true,
      filter: true,
      width: 125,
      hide: true,
      cellRenderer: (params) => {
        return params.data?.watched
          ? <span style={{
            display: 'flex',
            alignItems: 'center',
          }}><ColoredDot color="green" /></span>
          : <span style={{
            display: 'flex',
            alignItems: 'center',
          }}><ColoredDot /></span>
      },
    },
    {
      headerName: "Borrower",
      field: "borrowerName",
      filterParams: {
        valueFormatter: valueFormatter
      },
      sortable: true,
      filter: true,
      width: 150,
    },
    {
      headerName: "UPB",
      field: "upb",
      sortable: true,
      filter: 'agNumberColumnFilter',
      width: 150,
      cellStyle: { fontFamily: 'IBM Plex Mono' },
      cellRenderer: (params) => {
        if (!params?.data?.upb) return '';
        return parseCurrency(params?.data?.upb, 2);
      }
    },
    {
      headerName: "Address",
      field: "assetAddress",
      filterParams: {
        valueFormatter: valueFormatter
      },
      sortable: true,
      filter: true,
      width: 200,
      hide: true,
    },
    {
      headerName: "State",
      field: "state",
      sortable: true,
      filter: true,
      width: 130,
    },
    {
      headerName: "Tasks",
      field: "tasks",
      sortable: true,
      filter: 'agNumberColumnFilter',
      width: 125,
      cellStyle: { fontFamily: 'IBM Plex Mono' },
    },
    {
      headerName: "Active Process",
      field: "activeStatus",
      filterParams: {
        valueFormatter: valueFormatter
      },
      sortable: true,
      filter: true,
      width: 200,
    },
    {
      headerName: "Current Step",
      field: "statusValue",
      filterParams: {
        valueFormatter: valueFormatter
      },
      sortable: true,
      filter: true,
      width: 150,
      cellRenderer: "StageBars",
      cellStyle: { fontFamily: 'IBM Plex Mono' },
    },
    {
      headerName: "Step Label",
      field: "stepLabel",
      filterParams: {
        valueFormatter: valueFormatter
      },
      sortable: true,
      filter: true,
      width: 250,
    },
    {
      headerName: "Latest Comment",
      field: "latestComment",
      sortable: true,
      filter: true,
      filterParams: {
        valueFormatter: valueFormatter
      },
      width: 225,
      hide: true,
    },
    {
      headerName: "Days on Hold",
      field: "daysOnHold",
      sortable: true,
      filter: 'agNumberColumnFilter',
      width: 200,
      hide: true,
    },
    {
      headerName: "Hold Type",
      field: "holdType",
      sortable: true,
      filter: true,
      width: 200,
      hide: true,
    },
    {
      headerName: "Days in FC",
      field: "daysInFC",
      sortable: true,
      filter: 'agNumberColumnFilter',
      width: 175,
    },
    {
      headerName: "Days in BK",
      field: "daysInBK",
      sortable: true,
      filter: 'agNumberColumnFilter',
      width: 175,
      hide: true,
    },
    {
      headerName: "Days in DLQ",
      field: "daysInDlq",
      sortable: true,
      filter: 'agNumberColumnFilter',
      width: 175,
      hide: true,
    },
    {
      headerName: "Step Budget Days",
      field: "stepBudgetDays",
      sortable: true,
      filter: 'agNumberColumnFilter',
      width: 175,
    },
    {
      headerName: "Step Days Overdue",
      field: "stepDaysOverdue",
      sortable: true,
      filter: 'agNumberColumnFilter',
      width: 175,
      hide: true,
      cellRenderer: 'ColoredCell',
    },
    {
      headerName: "Total Net Vs Budget",
      field: "netVsBudget",
      sortable: true,
      filter: 'agNumberColumnFilter',
      width: 175,
      hide: true,
      cellRenderer: 'ColoredCell',
    },
    {
      headerName: "Total Gross Vs Budget",
      field: "grossVsBudget",
      sortable: true,
      filter: 'agNumberColumnFilter',
      width: 175,
      hide: true,
      cellRenderer: 'ColoredCell',
    },
    {
      headerName: "Budget Days to Sale",
      field: "budgetDaysToSale",
      sortable: true,
      filter: 'agNumberColumnFilter',
      width: 175,
      hide: true,
    },
    {
      headerName: "Docs",
      field: "documentsCount",
      sortable: true,
      filter: true,
      width: 175,
      cellRenderer: 'DocumentsButton',
      cellClass: 'ag-show-dropdown-modal',
    },
    {
      headerName: "Referral",
      field: "referredLawFirm",
      sortable: true,
      filter: true,
      filterParams: {
        valueFormatter: valueFormatter
      },
      direction: 'rtl',
      cellRenderer: 'LawfirmCell',
      cellClass: 'ag-show-dropdown-modal',
      width: 150,
      onCellClicked: (e) => {
        if (e.column.colId !== 'servicer') {
          e.node.setSelected(true);
        }
      },
    },
    {
      headerName: "Team",
      field: "assignedTeams",
      sortable: true,
      filter: true,
      filterParams: {
        valueFormatter: valueFormatter
      },
      direction: 'rtl',
      cellRenderer: 'TeamCell',
      cellClass: 'ag-show-dropdown-modal',
      width: 175,
      hide: true,
      onCellClicked: (e) => {
        if (e.column.colId !== 'servicer') {
          e.node.setSelected(true);
        }
      },
    },
  ]);

  const { data, loading } = useQuery(ASSETS_QUERY, {
    variables: { portfolioID },
    skip: !portfolioID,
    pollInterval: parseInt(process.env.REACT_APP_BIG_POLL_INTERVAL),
    fetchPolicy: 'no-cache',
  });

  const userWatchlist = useQuery(GET_USER_WATCHLIST);

  const teamsRes = useQuery(GET_TEAMS_BY_OWNER, {
    variables: {
      portfolioManager: user.id
    },
  });

  const gridOptions = {
    suppressPropertyNamesCheck: true,
    columnDefs,
    rowSelection: "multiple",
  };

  const onGridReady = useCallback((params) => {
    gridApiRef.current = params.api;

    const columnState = params.columnApi.getColumnState();
    if (columnState) {
      params.columnApi.applyColumnState({
        state: columnState,
        applyOrder: true,
      });
      params.columnApi.autoSizeAllColumns();
    }
  }, []);

  useEffect(() => {
    if (userWatchlist?.data) {
      setWatchedAssets(userWatchlist.data.getUserInfo.watchlist)
      setUser({ ...user, watchlist: userWatchlist.data.getUserInfo.watchlist })
    }

    if (data?.findAssets) {
      const transformed = transformAssetData([...data.findAssets]);
      setItems(transformed);
    }
  }, [teamsRes, data, userWatchlist, watchedAssets]);

  useEffect(() => {
    const visibleColumns = ls.getItem('assetList') ? JSON.parse(ls.getItem('assetList')) : [];
    const newCols = [...columnDefs].map((col) => {
      const found = visibleColumns.find((vc) => vc.colId === col.field);
      if (found) col.hide = found.hide;
      return col;
    });
    if (newCols) setColumnDefs(newCols);
  }, [selectedTab]);

  if (!portfolioID)
    return null;

  if (loading || teamsRes?.loading)
    return (
      <TableSkeleton columnDefs={columnDefs} />
    );

  function onCellClicked(e) {
    if (e.column.colId === 'subscribed' || e.column.colId === 'servicer' || e.column.colId === "options") return;
    const assetId = gridApiRef.current.getSelectedRows()[0]?.id;
    
    if (assetId) {
      const win = window.open(`/assets/${assetId}`, "_blank");
      win?.focus();
    } 
    e.node.setSelected(false);
    
  }

  const onCheckboxClick = () => {
    const rows = gridApiRef.current.getSelectedRows();
    setSelectedAssets(rows);
  }

  const onGridSizeChanged = (params) => {
    const cols = params.columnApi.getColumns();
    const numVisible = cols.filter(c => c.visible).length
    if (numVisible <= 11)
      gridApiRef.current.sizeColumnsToFit();
    else
      params.columnApi.autoSizeAllColumns();
  };

  function transformAssetData(assets) {
    if (!assets || assets.length === 0) return [];
    return assets.map((item) => {
      const isWatched = watchedAssets && watchedAssets.includes(item.id);
      const currProcess = item.activeProcess || null;
      let numActiveTasks = 0;
      const data = { ...item };

      if (isWatched) data.watched = true;
      else data.watched = false;

      if (teamsRes?.data?.findTeamsByOwner) {
        const noLawfirms = teamsRes.data.findTeamsByOwner.filter((team) => team.type !== "LAWFIRM");
        const assignedTeams = [];
        noLawfirms.forEach((team) => {
          const teamAssets = team.assets;
          teamAssets.forEach((teamAsset) => {
            if (teamAsset.id === item.id) {
              assignedTeams.push(team);
            }
          })
        })
        data.assignedTeams = assignedTeams;
      }

      // loan_status data
      data.borrowerName = `${item.borrowerFirstName} ${item.borrowerLastName}`.replace("null", "");

      const isBK = (process) => process === "chapter11" || process === "chapter13" || process === "chapter7"

      if (currProcess) {
        data.daysInFC = item?.loan_status?.totalDaysInFc === 0 ? 0 : item?.loan_status?.totalDaysInFc || null;
        data.daysInBK = item?.loan_status?.totalDaysInBk === 0 ? 0 : item?.loan_status?.totalDaysInBk || null;
        data.daysOnHold = item?.loan_status?.stepDaysOnHold === 0 ? 0 : item?.loan_status?.stepDaysOnHold || null;
        data.stepBudgetDays = item?.loan_status?.stepBudgetDays === 0 ? 0 : item?.loan_status?.stepBudgetDays || null;
        data.stepDaysOverdue = item?.loan_status?.stepDaysOverdue === 0 ? 0 : item?.loan_status?.stepDaysOverdue || null;
        data.totalDaysOverdue = item?.loan_status?.totalDaysOverdue === 0 ? 0 : item?.loan_status?.totalDaysOverdue || null;
        data.netVsBudget = item?.loan_status?.netVsBudget === 0 ? 0 : item?.loan_status?.netVsBudget || null;
        data.grossVsBudget = item?.loan_status?.grossVsBudget === 0 ? 0 : item?.loan_status?.grossVsBudget || null;
        data.daysInDlq = item?.loan_status?.daysInDlq === 0 ? 0 : item?.loan_status?.daysInDlq || null;
        data.budgetDaysToSale = item?.loan_status?.budgetDaysToSale === 0 ? 0 : item?.loan_status?.budgetDaysToSale || null;
        data.stepDaysOnHold = item?.loan_status?.stepDaysOnHold === 0 ? 0 : item?.loan_status?.stepDaysOnHold || null;
        data.activeStatus = isBK(currProcess.processType) ? "Bankruptcy" : "Foreclosure";
      }

      const totalComments = [];

      // activity data
      if (item.activities.length) {
        const activityComments = item.activities.map((activity) => activity.comments).flat().filter(c => c);

        if (activityComments.length) {
          totalComments.push({
            comment: activityComments[activityComments.length - 1].description,
            date: activityComments[activityComments.length - 1].createdDate
          })
        }

        const activityTasks = item.activities.map((activity) => activity.tasks).flat().filter(t => t);
        numActiveTasks += activityTasks.filter((task) => !task.completedAt).length;

        const taskComments = activityTasks.map((task) => task.task_comments).flat().filter((tc) => tc);
        if (taskComments.length) {
          totalComments.push({
            comment: taskComments[taskComments.length - 1].comment
              || taskComments[taskComments.length - 1].description,
            date: taskComments[taskComments.length - 1].createdAt
          })
        }

        const taskFlows = item.activities.map((activity) => activity.task_flows).flat().filter((tf) => tf);
        numActiveTasks += taskFlows.filter((tf) => !tf.completedAt).length;

        const taskFlowComments = taskFlows.map((tf) => tf.task_comments).flat().filter((tc) => tc);
        if (taskFlowComments.length) {
          totalComments.push({
            comment: taskFlowComments[taskFlowComments.length - 1].comment
              || taskFlowComments[taskFlowComments.length - 1].description,
            date: taskFlowComments[taskFlowComments.length - 1].createdAt
          })
        }

        if (taskFlows.length) {
          const subtasks = taskFlows.map((tf) => tf.subtasks).flat();
          const subtaskComments = subtasks.map((subtask) => subtask.task_comments).flat().filter((tc) => tc);
          if (subtaskComments.length) {
            totalComments.push({
              comment: subtaskComments[subtaskComments.length - 1].comment
                || subtaskComments[subtaskComments.length - 1].description,
              date: subtaskComments[subtaskComments.length - 1].createdAt
            })
          }
        }
      }

      // process data
      if (currProcess) {
        if (currProcess?.comments.length) {
          totalComments.push({
            comment: currProcess.comments[currProcess.comments.length - 1].description,
            date: currProcess.comments[currProcess.comments.length - 1].createdDate
          })
        }
        if (currProcess.tasks.length) {
          numActiveTasks += currProcess.tasks.filter((task) => !task.completedAt).length;

          const hasComments = currProcess.tasks.find((task) => task?.task_comments?.length);
          if (hasComments) {
            const tasksWithComments = currProcess.tasks.filter((task) => task?.task_comments?.length);
            const taskComments = tasksWithComments.map((task) => task.task_comments).flat();

            totalComments.push({
              comment: taskComments[taskComments.length - 1].comment
                || taskComments[taskComments.length - 1].description,
              date: taskComments[taskComments.length - 1].createdAt
            })
          }
        }
        if (currProcess.task_flows?.length) {
          numActiveTasks += currProcess.task_flows.filter((tf) => !tf.completedAt).length;

          const hasComments = currProcess.task_flows.find((tf) => tf?.task_comments?.length);
          if (hasComments) {
            const taskFlowsWithComments = currProcess.task_flows.filter((tf) => tf?.task_comments?.length);
            const taskFlowComments = taskFlowsWithComments.map((tf) => tf.task_comments).flat();

            totalComments.push({
              comment: taskFlowComments[taskFlowComments.length - 1].comment
                || taskFlowComments[taskFlowComments.length - 1].description,
              date: taskFlowComments[taskFlowComments.length - 1].createdAt
            })
          }

          // subtasks
          const subtasks = currProcess.task_flows.map((tf) => tf.subtasks).flat().filter((subtask) => subtask);
          const subtaskComments = subtasks.map((subtask) => subtask?.task_comments).flat();
          if (subtaskComments.length) {
            totalComments.push({
              comment: subtaskComments[subtaskComments.length - 1].comment
                || subtaskComments[subtaskComments.length - 1].description,
              date: subtaskComments[subtaskComments.length - 1].createdAt
            })
          }
        }
        if (currProcess.holds.length) {
          const holds = [...currProcess.holds].sort((a, b) => b.id - a.id);
          const activeHold = holds.find(hold => hold.active !== false);

          if (activeHold) {
            data.holdReason = activeHold.holdComment;
            data.holdType = activeHold.holdType;
          }

          const hasComments = currProcess.holds.find((hold) => hold.hold_comments.length);
          if (hasComments) {
            const holdsWithComments = currProcess.holds.filter((hold) => hold?.hold_comments?.length);
            const holdComments = holdsWithComments.map((hold) => hold.hold_comments).flat();

            totalComments.push({
              comment: holdComments[holdComments.length - 1].comment
                || holdComments[holdComments.length - 1].description,
              date: holdComments[holdComments.length - 1].createdAt
            })
          }
        }
        if (currProcess.referral) {
          if (currProcess.referral.referralStatus === 'ACCEPTED' && !currProcess.referral.deleted) {
            data.referredLawFirm = currProcess.referral.team.name;
          } else if (currProcess.referral.referralStatus === 'PENDING' && !currProcess.referral.deleted) {
            data.referredLawFirm = 'Pending';
          } else if (currProcess.referral.referralStatus === 'REJECTED' && !currProcess.referral.deleted) {
            data.referredLawFirm = 'Rejected';
          }
        }
        data.statusValue = currProcess.stepID;
        data.stepLabel = currProcess.processSteps.find((step) => step.stepID === currProcess.stepID).label;
      }

      if (totalComments.length) {
        const latest = totalComments.reduce((a, b) => {
          return new Date(+a.date) > new Date(+b.date) ? a : b;
        });
        data.latestComment = latest.comment;
      }

      if (item.documents) {
        data.documentsCount = item.documents.length || '0';
      }
      data.tasks = numActiveTasks;

      data.isCC = assets.filter((a) => a.loanNumber === item.loanNumber).length > 1 ? true : false;

      return data;
    });
  }

  if (items.length === 0 && user.userType == "PORTFOLIO_MANAGER") {
    return (
      <div style={{
        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '80%'
      }}>
        <ImportAssets show={showImportAssetsModalFirst} onClose={() => setShowImportAssetsModalFirst(false)} firstImport={true} />
        <Typography variant="h4" component="h1" mb={4}>
          You don&apos;t have data to show.
        </Typography>
        <Button sx={{ width: 'auto' }} onClick={() => setShowImportAssetsModalFirst(true)}>Import Assets</Button>
      </div>
    )
  }

  const closeActions = () => setActionButtonAnchor(null);
  const clickActionItem = (option) => {
    if (option === "AssignAsset") {
      setShowTeamReferral(true);
      closeActions();
      return;
    } else if (option === "ReferAsset") {
      setShowLawfirmReferral(true);
      closeActions();
      return;
    } else if (option === "WatchAsset") {
      setShowUpdateWatchlist(true);
      closeActions();
    }
  }

  return (
    <>
      {user.userType == "PORTFOLIO_MANAGER" && <ImportAssets show={showImportAssetsModal} onClose={() => setShowImportAssetsModal(false)} firstImport={false} /> }
      {user.userType == "PORTFOLIO_MANAGER" && <AssignAssetToTeam open={showTeamReferral} onClose={() => setShowTeamReferral(false)} user={user} assets={selectedAssets.length && selectedAssets} client={client} /> }
      {user.userType == "PORTFOLIO_MANAGER" && <ReferAssetToLawFirm open={showLawfirmReferral} onClose={() => setShowLawfirmReferral(false)} user={user} assets={selectedAssets.length && selectedAssets} /> }
      <>
        {shouldShowRefreshButton && <APIUpdate portfolioID={portfolioID} lastApiImportDate={lastApiImportDate} assetCount={items.length} hasActiveProcess={items.some((asset) => asset.activeProcess)} client={client} />}
        <StandardGrid
          selectedTab={selectedTab}
          tableName="assetList"
          onGridReady={onGridReady}
          onGridSizeChanged={onGridSizeChanged}
          rowData={items}
          onCellClicked={onCellClicked}
          onSelectionChanged={onCheckboxClick}
          pagination={true}
          gridOptions={gridOptions}
          components={frameworkComponents}
          paginationPageSize={limit}
          // autoGroupColumnDef={autoGroupColumnDef}
          groupRemoveSingleChildren={true}
          groupRemoveLowestSingleChildren={false}
          groupDefaultExpanded={-1}
          suppressAggFuncInHeader={true}
          floatingButton={
            <Button onClick={() => setShowImportAssetsModal(true)}>Import</Button>
          }
        />
        {selectedAssets.length > 0 && 
            <>
              <Floating>
                <Button onClick={(e) => {
                  setActionButtonAnchor(e.currentTarget)
                }}>
                  Actions +
                </Button>
              </Floating>
              <Menu
                anchorEl={actionButtonAnchor}
                open={Boolean(actionButtonAnchor)}
                onClose={closeActions}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              >
                {user.userType === "PORTFOLIO_MANAGER" && (
                  <>
                    <MenuItem onClick={() => clickActionItem("AssignAsset")}>
                        Assign to Team
                    </MenuItem>
                    {/* <MenuItem onClick={() => clickActionItem("ReferAsset")}>Refer to Law Firm</MenuItem> */}
                  </>
                )}
                <MenuItem onClick={() => clickActionItem("WatchAsset")}>Update Asset Watchlist</MenuItem>
              </Menu>
            </>
        }
        <UpdateAssetWatchlist
          open={showUpdateWatchlist}
          onClose={() => {setShowUpdateWatchlist(false)}}
          user={user}
          assets={selectedAssets}
        />
      </> 

    </>
  );
}