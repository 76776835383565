import React, { useState, useContext } from 'react';
import { useMutation } from '@apollo/client';
import Modal from '../../styled/Modal/Modal';
import { gql } from "@apollo/client";
import { Box } from '@mui/material';
import Button from '../../styled/Button/Button';
import { GET_TABLE_FILTERS } from './Grid';
import { UserContext } from '../../context/UserContext';
import Typography from '../../styled/Typography/Typography';
import { TextInput } from '../../styled/TextInput/TextInput';
import styled from "@emotion/styled";

const SAVE_TABLE_FILTER = gql`
  mutation createTableFilter($input: TableFilterInput) {
    createTableFilter(input: $input) {
      id
      name
      tableName
    }
  }
`;

const NameContainer = styled(Box)`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 2% 0 2% 0;
  width: 100%;
`;
const Input = styled(TextInput)`
  width: 40%;
`;

const valueFormatter = (value) => {
  if (!value) return;
  if (value?.operator) {
    // is using more than one filter for a column
    return (
      <Box>
        {value.conditions.map((condition, index) => (
          <li key={`${condition}-${index}`}
            style={{listStyleType: 'none'}}
          >
            {
              condition.filterType === "set" ? 
              `${keyFormatter(condition.type)} ${condition.values.join(', ')}` :
              `${keyFormatter(condition.type)} ${condition.filter}`
            }
          </li>
        ))}
      </Box>
    )
  }


  if (value.filterType === "set") {
    return (
      <Box>
        {value.values.map((val, index) => (
          <li key={`${val}-${index}`}
            style={{listStyleType: 'none'}}
          >{val}</li>
        ))}
      </Box>
    )
  } else if (value.filterType === "number") {
    return (
      <Box>
        <li
          style={{listStyleType: 'none'}}
        >{keyFormatter(value.type)} {value.filter}</li>
      </Box>
    )
    
  }
};

const keyFormatter = (key) => {
  if (!key) return;
  const capitalize = (word) =>
    word.charAt(0).toUpperCase() + word.substring(1);

  const words = key.match(/([A-Z]?[^A-Z]*[a-z]+|[A-Z]{2,}|[A-Z][a-z]*|[^A-Z]+)/g) || [];

  return words.map((word, index) => {
    // Check for the last part of the string being exactly two capital letters
    if (index === words.length - 1 && word.length === 2 && /^[A-Z]{2}$/.test(word)) {
      return word;
    }

    return capitalize(word);
  }).join(" ");
};

const SaveFilterModal = ({ open, setOpen, tableName, filters, setCurrFilter, filterRef }) => {
  const { portfolioID, teamID } = useContext(UserContext);

  const [filterName, setFilterName] = useState('');

  const [createTableFilter] = useMutation(SAVE_TABLE_FILTER, {
    refetchQueries: [{ query: GET_TABLE_FILTERS, variables: { tableName } }]
  });

  const handleSaveFilter = async () => {
    if (!filterName) return;
    const input = {
      name: filterName,
      tableName,
      filters,
    };
    if (portfolioID) input.portfolio = portfolioID;
    if (teamID) input.team = teamID;

    try {
      const created = await createTableFilter({ variables: { input } });
      filterRef.current = {
        id: created.data.createTableFilter.id,
        name: created.data.createTableFilter.name,
        filters: filters,
        tableName: tableName
      };
      setFilterName('');
      setOpen(false);
      setCurrFilter({});
    } catch(err) {
      console.log(err)
    }
  };

  const handleNameChange = (e) => {
    setFilterName(e.target.value);
  };

  return (
    <Modal open={open} setOpen={setOpen} onClose={() => setOpen(false)}>
      <Typography variant="h4">Applied Filters</Typography>
      {Object.keys(filters).length > 0 && Object.entries(filters).map(([key, value]) => {
        return (
          <dl key={key}>
            <dt style={{paddingLeft: "20%", fontWeight: 'bold'}}>{keyFormatter(key)}: </dt>
            {valueFormatter(value)}
          </dl>
        )
      })}

      <NameContainer>
        <Input label="Filter Name" onChange={handleNameChange}/>
        <Button
          disabled={!filterName.length}
          onClick={handleSaveFilter}
          sx={{width: '40%'}}
        >
        Save
        </Button>
      </NameContainer>
    </Modal>
  );
};

export default SaveFilterModal;