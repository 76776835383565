import { ButtonGroup, Grow, Paper, Popper, ClickAwayListener, MenuList, MenuItem } from '@mui/material';
import React, { useState, useRef } from 'react';
import Button from '../../../styled/Button/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AddTask from '../../Modals/AddTask';
import OpenHold from '../../Modals/OpenHold';
import TaskFlowList from '../../TaskFlow/TaskFlowList';
import CreateTaskFlowTemplate from '../../TaskFlowTemplates/CreateTaskFlow';

const ADD_TASK = "Add Task";
const OPEN_HOLD = "Open Hold"
const ADD_TASK_FLOW = "Add Task Flow";
let options = [ADD_TASK, OPEN_HOLD, ADD_TASK_FLOW];

export default function AddTaskAndHold({
  substeps,
  portfolioID,
  milestoneID,
  asset,
  process,
  openTask,
  setOpenTask,
  openHold,
  setOpenHold,
  addTaskFlow,
  setAddTaskFlow,
  setScreen,
  activity,
  processType,
  activeProcess,
  setActiveProcess,
  setAsset,
  isProperty
}) {
  const anchorRef = useRef(null);

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const [showTaskFlowDrawer, setShowTaskFlowDrawer] = useState(false);

  if (activity?.id && !process?.id) {
    options = [ADD_TASK, ADD_TASK_FLOW];
  } else options = [ADD_TASK, OPEN_HOLD, ADD_TASK_FLOW];

  const handleClick = () => {
    switch (options[selectedIndex]) {
    case ADD_TASK:
      setOpenTask(true);
      return;
    case OPEN_HOLD:
      setOpenHold(true)
      return;
    case ADD_TASK_FLOW:
      setAddTaskFlow(true)
      return;
    }
  };

  const handleMenuItemClick = async (event, option, index) => {
    switch (option) {
    case ADD_TASK:
      setOpenTask(true);
      return;
    case OPEN_HOLD:
      setOpenHold(true)
      return;
    case ADD_TASK_FLOW:
      setAddTaskFlow(true)
      return;
    }

    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const onClickCreate = () => {
    setAddTaskFlow(false);
    setShowTaskFlowDrawer(true);
  }

  return (
    <>
      <ButtonGroup ref={anchorRef} aria-label="split button" style={{ height: '75%', width: '155px' }}>
        <Button onClick={handleClick} style={{height: 'auto'}}>{options[selectedIndex]}</Button>
        <Button
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="Select an Action"
          aria-haspopup="menu"
          style={{ width: "10px", height: "auto" }}
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      selected={index === selectedIndex}
                      onClick={(event) => {
                        handleMenuItemClick(event, option, index)
                      }}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <AddTask
        show={openTask}
        asset={asset}
        activity={activity}
        processID={process?.id}
        milestoneID={milestoneID}
        substeps={substeps}
        onClose={() => {
          setOpenTask(false);
        }}
      />
      { !activity && process && 
        <OpenHold
          process={process}
          show={openHold}
          milestoneID={milestoneID}
          asset={asset}
          onClose={() => {
            setOpenHold(false);
          }}
          setScreen={setScreen}
          portfolioID={portfolioID}
          activeProcess={activeProcess}
          setActiveProcess={setActiveProcess}
          setAsset={setAsset}
          isProperty={isProperty}
        />}
      <TaskFlowList
        portfolioID={portfolioID}
        show={addTaskFlow}
        activity={activity}
        onClose={() => {
          setAddTaskFlow(false);
        }}
        onClickCreate={onClickCreate}
        processID={process?.id}
        assetID={asset?.id}
        milestoneID={milestoneID}
        processType={processType}
      />
      <CreateTaskFlowTemplate
        show={showTaskFlowDrawer}
        setShow={setShowTaskFlowDrawer}
        portfolioID={portfolioID}
        activity={activity}
      />
    </>
  );
}

