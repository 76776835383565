import React, { useContext, useEffect, useState } from "react";
import { NavLink, Link, useLocation, useHistory } from "react-router-dom";
import { useApolloClient, useLazyQuery, useQuery } from "@apollo/client";
import { UserContext } from "../../context/UserContext";
import { Button, Menu, MenuItem, Divider, Switch, Chip, Stack, Badge } from "@mui/material";
import { useTheme } from "@emotion/react";
import NotificationsDropdown from "../NotificationsDropdown/NotificationsDropdown";
import NotificationsIcon from '@mui/icons-material/Notifications';
import styled from "@emotion/styled";
import Logo from "../Logo/Logo";
import "./header.scss";
import { GET_PORTFOLIOS } from "../../apollo/queries/getPortfolios";
import { GET_TEAMS } from "../../apollo/queries/getTeams";
import { GET_NOTIFICATIONS } from "../../apollo/queries/getNotifications";

const StyledMenuItem = styled(MenuItem)`
  color: ${(props) => props.color ? props.theme.themeColor.brandPrimaryRed : "black"};
  text-decoration: underline;
  &:hover {
    text-decoration: underline;
  }
`;
const StyledDivider = styled(Divider)`
  margin: 0 15px;
  height: 70%;
  align-self: center;
`
const IconContainer = styled('ul')`
  display: flex;
  justify-content: space-between;
  width: 175px;
  align-content: center;
  max-width: 250px;
`
const StyledCircle = styled(Button)`
  background-color: ${(props) => props.theme ? props.theme.themeColor.userIcon.background : 'yellow'};
  color: ${(props) => props.theme? props.theme.themeColor.userIcon.text : "blue"};
  font-size: 12px;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  min-width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: center;
  text-align: center;
`;

const Header = ({ routes, handleChangeTheme }) => {
  const location = useLocation();
  const history = useHistory();
  const client = useApolloClient();
  const login = location.pathname === "/auth/login";
  const resetPassword = location.pathname === "/reset-password";
  const { user, setUser, setTeamID, setPortfolioID, portfolioName, setPortfolioName, teamID } = useContext(UserContext);
  const { userType } = user;
  const theme = useTheme();
  const [isDarkTheme, setIsDarkTheme] = useState(false);

  const [get_portfolios] = useLazyQuery(GET_PORTFOLIOS);
  const [get_teams] = useLazyQuery(GET_TEAMS, {
    variables: {
      input: {
        teamIDs: [teamID],
      }
    }
  });
  const { data: notificationData } = useQuery(GET_NOTIFICATIONS);

  const [notifications, setNotifications] = useState([]);
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);
  const [userAnchor, setUserAnchor] = useState(null);
  const [notificationsAnchor, setNotificationsAnchor] = useState(null);
  const userOpen = Boolean(userAnchor);
  const notificationsOpen = Boolean(notificationsAnchor);

  const userTheme = window.localStorage.getItem('theme');

  const getPortfolio = async () => {
    const portfolios = await get_portfolios();
    if (portfolios?.data?.getUserPortfolios?.length > 0) {
      setPortfolioName(portfolios.data.getUserPortfolios[0].name);
    }
  };

  const getTeam = async () => {
    const teams = await get_teams();
    if (teams.data.findTeams?.length > 0) {
      setPortfolioName(teams.data.findTeams[0].name);
    }
  }

  useEffect(() => {
    if (notificationData?.getNotifications) {
      const sorted = [...notificationData.getNotifications].sort((a, b) => {
        if (a.read === b.read) 
          return new Date(b.createdAt) - new Date(a.createdAt);
        return a.read - b.read;
      });

      setNotifications(sorted);
      setUnreadNotificationsCount(notificationData.getNotifications.filter(notification => !notification.read).length);
    }
  }, [notificationData]);

  useEffect(() => {
    if (!portfolioName) {
      if (userType === 'PORTFOLIO_MANAGER' || userType === 'PORTFOLIO_MEMBER') {
        getPortfolio();
      } else {
        getTeam();
      }
    }
  }, [portfolioName]);

  useEffect(() => {
    setIsDarkTheme(userTheme === 'dark');
  }, [userTheme]);

  const handleClick = (event) => {
    setUserAnchor(event.currentTarget);
  };
  const handleClickNotifications = (event) => {
    setNotificationsAnchor(event.currentTarget);
  };
  const handleClose = async () => {
    setUserAnchor(null);
    setNotificationsAnchor(null);
  };

  if (login || resetPassword) return <div />;

  function handleLogout() {
    setUser(null);
    setTeamID(null);
    setPortfolioID(null);
    setPortfolioName(null);
    client.clearStore();
    history.push("/auth/login");
    sessionStorage.clear();
  }
  
  const onChangeTheme = () => {
    setIsDarkTheme(!isDarkTheme);
    handleChangeTheme(!isDarkTheme);
  }

  return (
    <header 
      className="app-header"
      style={{ backgroundColor: theme.themeColor.backgroundBody }}
    >
      <Stack justifyContent='center' alignItems='center' direction='row'>
        <Link to="/" style={{height: "100%"}}>
          <Logo />
        </Link>
        {portfolioName && <Chip label={portfolioName} />}
      </Stack>
      <nav className="main-nav">
        <ul className="h-list">
          {routes.map((prop, key) => {
            if (prop.redirect) return null;
            if (prop.hidden) return null;
            if (prop.permissions && !prop.permissions.includes(userType))
              return null;

            return (
              <li key={key} style={{ width: '95px' }}>
                <NavLink
                  className="link"
                  to={(user?.accDeleted || user?.noTeamsOrPortfolio) ? "/" : prop.path}
                  style={{
                    color: theme.themeColor.bodySecondary
                  }}
                  activeClassName={
                    location.pathname === prop.path ? "active" : ""
                  }
                >
                  {/* {prop.icon && <Icon icon={prop.icon} />} */}
                  {/* {prop.reactIcon && <ReactIcon icon={prop.reactIcon} />} */}
                  {prop.navbarName}
                </NavLink>
              </li>
            );
          })}
          <StyledDivider orientation='vertical' color="gray" variant="middle"/>

          <li>
            <NavLink
              className="link"
              to={`/user-manual`}
              style={{
                color: theme.themeColor.bodySecondary
              }}
            >
              User Manual
            </NavLink>
          </li>

          <StyledDivider orientation='vertical' color="gray" variant="middle"/>
          <IconContainer>
            <span style={{alignSelf: 'center'}}>
              <Badge badgeContent={unreadNotificationsCount} color="primary" anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}>
                <StyledCircle
                  style={{backgroundColor: 'inherit'}}
                  children={<NotificationsIcon sx={{color: 'gray'}} fontSize="small"/>}
                  onClick={handleClickNotifications}
                />
              </Badge>
              <NotificationsDropdown notifications={notifications} anchor={notificationsAnchor} open={notificationsOpen} onClose={handleClose} />
            </span>
            <span
              style={{alignSelf: 'center'}}
              onClick={handleClick}
              className="d-flex justify-content-center w-auto"
            >
              <StyledCircle
                theme={theme}
                children={<span>{user.firstName[0]}{user.lastName[0]}</span>}
              />
            </span>

            <span>
              <Switch 
                checked={isDarkTheme}
                onChange={onChangeTheme}
              />
            </span>

            <Menu
              id="basic-menu"
              anchorEl={userAnchor}
              open={userOpen}
              onClose={handleClose}
            >
              <StyledMenuItem onClick={() => {
                handleClose();
                history.push('/account-settings');
              }}>
                Account
              </StyledMenuItem>
              <StyledMenuItem 
                color="red"
                onClick={() => {
                  handleClose();
                  handleLogout();
                }}>
                  Sign Out
              </StyledMenuItem>
            </Menu>
          </IconContainer>
        </ul>
      </nav>
    </header>
  );
};
export default Header;
