import React, { useRef, useState, useEffect, useContext } from "react";
import { useQuery } from "@apollo/client";
import { REFERRALS_BY_PORTFOLIO_QUERY } from "../../apollo/queries/referralsByPortfolioQuery";
import { REFERRALS_BY_TEAM_QUERY } from "../../apollo/queries/referralsByTeamQuery";
import AssetReferralStatus from "./AssetReferralStatus";
import "./asset-referral-table.scss";
import StandardGrid from "../Grid/Grid";
import TableSkeleton from "../Skeleton/TableSkeleton";
import ReferralStatus from "./ReferralStatus";
import { UserContext } from "../../context/UserContext";

const frameworkComponents = {
  AssetReferralStatus,
  ReferralStatus
};

const ls = window.localStorage;

export default function AssetReferralTable({ selectedTab, setNumReferrals }) {
  const { portfolioID, teamID } = useContext(UserContext);
  const gridApiRef = useRef(null);

  const [referrals, setReferrals] = useState([]);
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Loan #",
      field: "loanNumber",
      sortable: true,
      filter: true,
      // width: 200,
      cellStyle: { fontFamily: 'IBM Plex Mono' },
      // headeboxSelection: user.userType === "TEAM_MANAGER" || user.userType === "TEAM_MEMBER",
    },
    {
      headerName: "Property ID",
      field: "propertyID",
      sortable: true,
      filter: true,
      cellStyle: { fontFamily: 'IBM Plex Mono' },
    },
    {
      headerName: "Process Type",
      field: "processType",
      sortable: true,
      filter: true,
      // width: 350,
      // width: isTeam ? 150 : 200,
    },
    {
      headerName: "State",
      field: "state",
      sortable: true,
      filter: true,
      // width: 100,
    },
    {
      headerName: "Referred To",
      field: "referredTo",
      sortable: true,
      filter: true,
      // width: 350,
      // width: isTeam ? 150 : 200,
    },
    {
      headerName: "Referred By",
      field: "referredBy",
      sortable: true,
      filter: true,
      // width: 200,
    },
    {
      headerName: "Status",
      field: "referralStatus",
      suppressColumnsToolPanel: true,
      filter: true,
      
      sortable: true,
      // width: 100,
      cellRenderer: 'ReferralStatus',
    },
    {
      headerName: "Referral Status",
      suppressColumnsToolPanel: true,
      field: "referralStatus_1",
      // width: 200,
      // width: isTeam ? 200 : 100,
      cellRenderer: 'AssetReferralStatus',
      cellClass: "ag-show-dropdown-modal",
      cellRendererParams: {
        setReferrals,
        referrals,
        gridApiRef,
      }
    },
  ]);

  const { loading: loadingPortfolio } = useQuery(REFERRALS_BY_PORTFOLIO_QUERY, {
    skip: !portfolioID,
    variables: {
      portfolioID: portfolioID,
    },
    pollInterval: parseInt(process.env.REACT_APP_BIG_POLL_INTERVAL),
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const { portfolioReferrals } = data;
      if (!portfolioReferrals) return;
      const rejected = [];
      const rest = [];
      const loanNums = [];

      portfolioReferrals.forEach((ref) => {
        ref.referralStatus === "REJECTED"
          ? rejected.push(ref)
          : (
            rest.push(ref),
            loanNums.push(ref.loanNumber)
          )
      })
      rejected.forEach((ref) => {
        if (!loanNums.includes(ref.loanNumber)) {
          rest.push(ref)
        }
      })

      setReferrals(transformData(rest));
    }
  });

  const { loading: loadingTeam } = useQuery(REFERRALS_BY_TEAM_QUERY, {
    skip: !teamID,
    variables: {
      teamID: teamID,
    },
    pollInterval: parseInt(process.env.REACT_APP_BIG_POLL_INTERVAL),
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const notDeleted = data.teamReferrals.filter(
        (item) => !item.deleted
      )
      setReferrals(transformData(notDeleted))
    }
  });

  const gridOptions = {
    suppressPropertyNamesCheck: true,
    pagination: true,
    rowSelection: "single",
    columnDefs,
  };

  useEffect(() => {
    const visibleColumns = ls.getItem('assetReferralTable') ? JSON.parse(ls.getItem('assetReferralTable')) : [];
    const newCols = [...columnDefs].map((col) => {
      const found = visibleColumns.find((vc) => vc.colId === col.field);
      if (found) col.hide = found.hide;
      return col;
    });
    if (newCols) setColumnDefs(newCols);
  }, [selectedTab]);

  function onGridReady(params) {
    gridApiRef.current = params.api;
  }

  function onGridSizeChanged() {
    gridApiRef.current.sizeColumnsToFit();
  }

  function onCellClicked(e) {
    const asset = gridApiRef.current.getSelectedRows()[0];
    if (e.column.colDef.field === "referralStatus_1") {
      return;
    }

    if (asset.referralStatus === "PENDING") return;

    if (asset?.assetID) {
      const assetId = asset.assetID;
      const win = window.open(`/assets/${assetId}`, "_blank");
      win.focus();
    }
  }

  function transformData(referrals) {
    let numPending = 0;
    
    const isBk = (process) => process === "chapter11" || process === "chapter13" || process === "chapter7"
    
    const transformed = referrals.map((item) => {
      if (item.referralStatus === "PENDING") numPending++;
      return {
        id: item.id,
        loanNumber: item.loanNumber,
        state: item.state,
        referredBy: `${item.referredBy.firstName} ${item.referredBy.lastName}`,
        referralStatus: item.referralStatus,
        assetID: item.assetID,
        processIDs: item?.referredProcesses?.map((p) => p.id),
        seenByCreator: item.seenByCreator,
        referredTo: item?.team?.name,
        processType: isBk(item?.referredProcesses?.map((p) => p.processType).join(", ")) ? 'Bankruptcy' : 'Foreclosure',
        propertyID: isBk(item?.referredProcesses?.map((p) => p.processType).join(", ")) && item.propertyID ? 'ALL' : item.propertyID 
      }
    });
    if (setNumReferrals)
      setNumReferrals(numPending);
    return transformed;
  }

  if (loadingPortfolio || loadingTeam) return (
    <TableSkeleton columnDefs={columnDefs} />
  )

  return (
    <StandardGrid
      tableName="assetReferralTable"
      onGridReady={onGridReady}
      rowData={referrals}
      onCellClicked={onCellClicked}
      gridOptions={gridOptions}
      components={frameworkComponents}
      onGridSizeChanged={onGridSizeChanged}
    />
  );
}