import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { rgba } from "emotion-rgba";
import React, { useContext, useState, useEffect } from "react"
import Drawer from "../../styled/Drawer/Drawer";
import { MdKeyboardReturn } from 'react-icons/md';
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { GET_USERS_BY_PORTFOLIO_AND_TEAM } from "../../apollo/queries/getUsersByPortfolioAndTeam";
import { GET_TEAMS } from "../../apollo/queries/getTeams";
import { UPDATE_TASK_MUTATION } from "../../apollo/mutations/updateTask";
import { COMMENT_TASK_MUTATION } from "../../apollo/mutations/commentTaskMutation";
import { TASKS_QUERY } from "../../apollo/queries/tasksQuery";
import { EVENTS_QUERY } from "../../apollo/queries/eventsQuery";
import { MessageContext } from "../../context/MessageContext";
import { CREATE_EVENT_MUTATION } from "../../apollo/mutations/createTaskAndEventsMutation";
import { UserContext } from "../../context/UserContext";
import { TASK_FLOWS_QUERY } from "../../apollo/queries/taskFlowsQuery";
import { UPDATE_SUBTASK_AND_EVENTS_MUTATION } from "../../apollo/mutations/updateSubtaskAndEventsMutation";
import { UPDATE_SUBTASK_MUTATION } from "../../apollo/mutations/updateSubtask";
import { UPDATE_TASK_FLOW_MUTATION } from "../../apollo/mutations/updateTaskflowMutation";
import TaskCommentsModal from "./TaskCommentsModal";
import Header from "./Header";
import AdditionalInfo from "./AdditionalInfo";
import TaskCommentsTimeline from "./TaskCommentsTimeline";
import { ACTIVITY_TASKS_QUERY } from "../../apollo/queries/activityTasksQuery";
import { ACTIVITY_TASK_FLOWS_QUERY } from "../../apollo/queries/activityTaskFlowsQuery";
import { GET_ACTIVITY_EVENTS } from "../../apollo/queries/getActivityEvents";
import { ASSET_QUERY } from "../../apollo/queries/assetQuery";
const { differenceInDays } = require('date-fns');

const Bottom = styled(Box)`
  display: flex;
  align-items: center;
  border-top: 1px solid ${({theme}) => rgba(theme.themeColor.bodyMain, 0.1)};
  height: 80px;
  background-color: ${({theme}) => theme.themeColor.sectionStroke};
  margin: 0 -25px;
  padding: 25px;
  position: absolute;
  bottom: 0;
  width: 100%;
  justify-content: space-around;
`

const CustomIcon = styled(Box)`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  background:  ${({theme}) => theme.themeColor.backgroundBody};
  border: 1px solid ${({theme}) => theme.themeColor.bodyTetriary};
  border-radius: 4px;
  font-family: 'IBM Plex Mono';
  min-width: 28px;
  height: 20px;
  font-weight: 700;
  font-size: 10px;
  line-height: 10px;
`
const removeDuplicateIDs = (array) => {
  return array.reduce(function (a, c) {
    if (!a.some((el) => el.id === c.id)) a.push(c);
    return a;
  }, []);
}

const TaskComments = ({ 
  open,
  taskName,
  onClose,
  milestoneID,
  process,
  taskId,
  comments,
  taskDescription,
  assignments,
  dueByMilestone,
  deadlineAt,
  createdAt,
  assetID,
  isSubtask,
  isTaskFlow,
  blocking,
  forms,
  readOnly,
  taskActivity,
}) => {
  const { addMessage } = useContext(MessageContext);
  const { portfolioID, teamID, user } = useContext(UserContext)
  const processID = process?.id;

  const [addToTimeline, setAddToTimeline] = useState(true);
  const [newComment, setNewComment] = useState('');
  const [formError, setFormError] = useState('');
  const [portfolioUsers, setPortfolioUsers] = useState(null);
  const [assignmentsValue, setAssignmentsValue] = useState([]);

  const { data } = useQuery(GET_USERS_BY_PORTFOLIO_AND_TEAM, {
    variables: { portfolioID, teamID, assetID },
  });
  const { data: teamData } = useQuery(GET_TEAMS);
  const [getAsset] = useLazyQuery(ASSET_QUERY);

  useEffect(async () => {
    if (data && teamData) {
      const assetRes = await getAsset({
        variables: { assetID, userID: user.userID },
        fetchPolicy: 'no-cache'
      });
      
      const asset = assetRes.data.asset;

      if (!asset) return;

      const teams = teamData.findTeams.filter((team) => {
        if (team.type === "EXTERNAL") {
          const teamAssetIDs = team.assets.map((asset) => asset.id);
          if (teamAssetIDs.includes(asset.id)) return team;
        } else if (team.type === "INTERNAL") {
          const teamPortfolioID = team.portfolio.id;
          if (asset.portfolio.id === teamPortfolioID) return team;
        } else if (team.type === "LAWFIRM") {
          // Show law firm that was sent the referral
          if (asset?.activeProcess?.referral?.teamID?.id === team.id) return team;
          // Show law firm that has accepted the referral
          const teamAssetIDs = team.assets.map((asset) => asset.id);
          if (teamAssetIDs.includes(asset.id)) return team;
        }
        else return team;
      });

      const portfolioUsers = data?.getUsersByPortfolioAndTeam?.length > 0 ? data.getUsersByPortfolioAndTeam : [];
      const teamUsers = teams.map((team) => team.memberships.map((member) => member.user)).flat();
  
      // Options should always start with the user itself
      let options = [{...user, __typename: "User"}];
      // Options is based on logged user type.
      if (user.userType === "PORTFOLIO_MANAGER") {
        // Portfolio Manager can see all users from all teams
        options = [...portfolioUsers, ...teamUsers, ...teams];
      } else if (user.userType === "TEAM_MANAGER" || user.userType === "TEAM_MEMBER") {
        // Portfolio Manager should always be available
        const portfolioManager = portfolioUsers.find((user) => user.userType === "PORTFOLIO_MANAGER");
        // It should show only the team that the user is part of
        const userTeams = teams.filter((team) => team.memberships.some((member) => member.user.id === user.userID));
        // It should always show users from the same teams
        const teamMembers = userTeams.map((team) => team.memberships.map((member) => member.user)).flat();
    
        options = [...options, portfolioManager, ...userTeams, ...teamMembers];
      }

      // Remove duplicates from options
      options = options.filter((option, index, self) =>
        index === self.findIndex((t) => (
          t.id === option.id && t.__typename === option.__typename
        ))
      );

      // Sort options by __typename
      options = options.sort((a, b) => {
        if (a.__typename === "User") return -1;
        if (b.__typename === "Team") return 1;
        return 0;
      });

      // Sort options by name
      const allOptions = options.sort((a, b) => {
        if (a.__typename === "User") {
          if (a.firstName < b.firstName) return -1;
          if (a.firstName > b.firstName) return 1;
          return 0;
        }
        if (b.__typename === "Team") {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        }
        return 0;
      });


      setPortfolioUsers(allOptions);
    }

    setAssignmentsValue(assignments);
  }, [data, teamData, assignments])

  const refetchQueries = taskActivity?.id
    ? [
      { query: ACTIVITY_TASKS_QUERY, variables: { activityID: taskActivity.id } },
      { query: ACTIVITY_TASK_FLOWS_QUERY, variables: { activityID: taskActivity.id } },
      { query: GET_ACTIVITY_EVENTS, variables: { activityID: taskActivity.id } },
    ]
    : [
      { query: TASKS_QUERY, variables: { processID } },
      { query: TASK_FLOWS_QUERY, variables: { processID } },
      { query: EVENTS_QUERY, variables: { milestoneID, processID } },
    ]


  const [addComment, { loading }] = useMutation(COMMENT_TASK_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: refetchQueries
  });

  const [createEvent] = useMutation(CREATE_EVENT_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: refetchQueries
  });
  
  const [reassignTask] = useMutation(UPDATE_TASK_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: refetchQueries
  });

  const [reassignSubtask] = useMutation(UPDATE_SUBTASK_AND_EVENTS_MUTATION, {
    refetchQueries: refetchQueries
  });

  const [reassignTaskflow] = useMutation(UPDATE_TASK_FLOW_MUTATION, {
    refetchQueries: refetchQueries
  });

  const [reassignFlowSubtasks] = useMutation(UPDATE_SUBTASK_MUTATION, {
    refetchQueries: refetchQueries
  })

  const handleSubmit = async () => {
    if (!newComment) {
      setFormError("Comment can't be empty.");
      return;
    }
    
    const data = {
      createdAt: new Date(),
      comment: newComment,
      addToTimeline,
    }

    if (isSubtask) data.subtask = taskId;
    else if (isTaskFlow) data.task_flow = taskId;
    else data.task = taskId;
    
    try {
      await addComment({
        variables: {
          input: data
        }
      });

      if (addToTimeline) {
        const event = {
          title: `Re: ${taskName}`,
          type: "task-comment",
          description: newComment,
          createdDate: new Date(),
        }

        if (taskActivity) {
          event.activity = taskActivity.id;
        } else {
          event.milestoneID = milestoneID;
          event.process = processID;
        }
        if (isTaskFlow) event.taskFlow = taskId;
        else if (isSubtask) event.subtask = taskId;
        else event.task = taskId;

        await createEvent({
          variables: {
            event
          }
        });
      }

      setNewComment('');
    } catch (error) {
      addMessage({ message: 'Unable to create a comment on this task. Please refresh and try again.', type: 'error' })
    }

  }

  const handleChange = (event) => {
    const value = event.target.value;
    setNewComment(value);
    setFormError('');
  }

  const handleReassignTask = async (_event, newValue) => {
    let teamNames = [];
    let names = [];
    const allValues = newValue.map((value) => {
      if (value.__typename === "User") {
        names.push(`${value.firstName} ${value.lastName}`)
        return value
      }
      else if (value.__typename === "Team") {
        teamNames.push(value.name)
        const members = value.memberships.map((member) => member.user);
        return members;
      }
    })
    const uniqueValues = removeDuplicateIDs(allValues.flat())

    setAssignmentsValue([
      ...uniqueValues,
    ]);

    if (newValue.length === 0) {
      addMessage({ message: "Please select someone to reassign the task to.", type: "error" });
      return;
    }
    const allNames = [...teamNames, ...names].join(', ')
    const description = `Task reassigned to ${allNames}`;

    const event = {
      type: "task-reassigned",
      dueBy: new Date(deadlineAt) || null,
      createdDate: new Date(),
      dueByMilestone,
      description,
      createdBy: user.id,
      title: taskName
    }

    if (taskActivity) event.activity = taskActivity.id;
    else {
      event.milestoneID = milestoneID;
      event.process = processID;
    }

    const task = {
      assignments: uniqueValues.map((a) => a.id),
    }

    try {
      if (isSubtask) {
        event.subtask = taskId;
        event.description = `Subtask reassigned to ${allNames}`;
        const resp = await reassignSubtask({
          variables: {
            subtask: { where: { id: taskId }, data: {assignments: uniqueValues.map((a) => a.id)} },
            event,
          },
        });

        if (!resp.data || resp.errors) {
          addMessage({ message: "Something went wrong while reassigning the subtask.", type: "error"});
        } else {
          addMessage({ message: "Subtask reassigned.", type: "success" });
        }
      } else if (isTaskFlow) {
        event.taskFlow = taskId;
        event.description = `Task flow reassigned to ${allNames}`;
        const flowResp = await reassignTaskflow({
          variables: {
            taskflow: { where: { id: taskId }, data: { assignments: uniqueValues.map((a) => a.id) } },
            event
          }
        })

        const subtaskQueries = isTaskFlow.map(async (subtask) => (
          await reassignFlowSubtasks({
            variables: {
              subtask: { where: { id: subtask.id }, data: { assignments: uniqueValues.map((a) => a.id) } }
            }
          })
        ))

        const subtaskResp = Promise.all(subtaskQueries)

        if (!flowResp.data || flowResp.errors || subtaskResp.errors) {
          addMessage({ message: "Something went wrong while reassigning the taskflow.", type: "error"});
        } else {
          addMessage({ message: "Task flow reassigned.", type: "success" });
        }
      } else {
        event.task = taskId;
        event.description = `Task reassigned to ${allNames}`;
        const resp = await reassignTask({
          variables: {
            task: { where: { id: taskId }, data: task },
          },
        });

        if (!resp.data || resp.errors) {
          addMessage({ message: "Something went wrong while reassigning the task.", type: "error"});
        } else {
          addMessage({ message: "Task reassigned.", type: "success" });
          await createEvent({
            variables: {
              event
            }
          });
        }

      }
    } catch (error) {
      addMessage({ message: 'Unable to reassign this task. Please refresh and try again.', type: 'error' })
    }
  }

  const updateTaskDate = async (newDeadline) => {
    const task = {
      deadlineAt: newDeadline
    }
    
    const event = {
      type: "task-duedate-update",
      dueBy: new Date(newDeadline) || null,
      createdDate: new Date(),
      milestoneID,
      process: processID,
      dueByMilestone,
      description: taskName,
      createdBy: user.id,
      title: taskName
    }
    if (taskId && !isSubtask) event.task = taskId;
    const oldDate = deadlineAt ? new Date(+deadlineAt).toLocaleDateString() : 'no date'
    const comment = {
      createdAt: new Date(),
      comment: `Deadline Updated from ${oldDate} to ${new Date(newDeadline).toLocaleDateString()}`,
      addToTimeline,
    }
    if (isSubtask) comment.subtask = taskId;
    else if (isTaskFlow) comment.task_flow = taskId;
    else comment.task = taskId;

    try {
      if (isSubtask) {
        const resp = await reassignSubtask({
          variables: {
            subtask: { where: { id: taskId }, data: { dueDate: newDeadline } },
            event,
          },
        })
        if ('errors' in resp) {
          addMessage({ message: "Something went wrong while reassigning the task.", type: "error"});
        } else {
          await addComment({
            variables: {
              input: comment
            }
          });
        }
      } else if (isTaskFlow) {
        const flowResp = await reassignTaskflow({
          variables: {
            taskflow: { where: { id: taskId }, data: { dueDate: newDeadline } },
            event
          }
        })
        const diffInDays = differenceInDays(newDeadline, new Date(+deadlineAt) || new Date());
        const subtaskQueries = isTaskFlow.map(async (subtask) => {
          const startDate = subtask.dueDate ? new Date(+subtask.dueDate) : new Date();
          const newDueDate = startDate.setDate(startDate.getDate() + diffInDays);
          const formattedDate = new Date(newDueDate).toISOString();

          return (
            await reassignFlowSubtasks({
              variables: {
                subtask: { where: { id: subtask.id }, data: { dueDate: formattedDate } }
              }
            })
          )
        })

        const subtaskResp = Promise.all(subtaskQueries)

        if ('errors' in flowResp || 'errors' in subtaskResp) {
          addMessage({ message: "Something went wrong while reassigning the task.", type: "error"});
        } else {
          await addComment({
            variables: {
              input: {
                data: comment
              }
            }
          });
        }
      } else {
        const resp = await reassignTask({
          variables: {
            task: { where: { id: taskId }, data: task },
          },
        });
    
        if ('errors' in resp) {
          addMessage({ message: "Something went wrong while reassigning the task.", type: "error"});
        } else {
          await createEvent({
            variables: {
              event
            }
          });
          await addComment({
            variables: {
              input: comment
            }
          });
        }
      }
    } catch(error) {
      console.log(error)
    } 
  }

  const updateTaskMilestone = async (e) => {
    const newMilestone = e.target.value;
    const task = { dueByMilestone: newMilestone };
    const oldMilestone = dueByMilestone ? dueByMilestone : 'none'
    const event = {
      type: "task-milestone-update",
      createdDate: new Date(),
      milestoneID,
      process: processID,
      dueByMilestone: newMilestone,
      description: `Task Milestone Deadline updated from ${oldMilestone} to ${newMilestone}`,
      createdBy: user.id,
      title: taskName
    }

    const comment = {
      createdAt: new Date(),
      comment: `Deadline Updated from ${oldMilestone} to ${newMilestone}`,
      addToTimeline,
      task: taskId
    }

    try {
      const resp = await reassignTask({
        variables: {
          task: { where: { id: taskId }, data: task },
        },
      });
    
      if ('errors' in resp) {
        addMessage({ message: "Something went wrong while reassigning the task.", type: "error"});
      } else {
        await createEvent({
          variables: {
            event
          }
        });
        await addComment({
          variables: {
            input: comment
          }
        });
      }
    } catch(error) {
      console.log(error)
    }
  }

  if (forms && forms.length > 0) {
    return (
      <TaskCommentsModal
        forms={forms}
        processID={processID}
        open={open}
        onClose={onClose}
        taskName={taskName}
        taskDescription={taskDescription}
        dueByMilestone={dueByMilestone}
        createdAt={createdAt}
        portfolioUsers={portfolioUsers}
        assignmentsValue={assignmentsValue}
        handleReassignTask={handleReassignTask}
        blocking={blocking}
        user={user}
        deadlineAt={deadlineAt}
        handleSubmit={handleSubmit}
        newComment={newComment}
        handleChange={handleChange}
        formError={formError}
        loading={loading}
        addToTimeline={addToTimeline}
        setAddToTimeline={setAddToTimeline}
        comments={comments}
        assetID={assetID}
        milestoneID={milestoneID}
      />
    )
  }

  return (
    <Drawer open={open} onClose={onClose} readOnly={readOnly} width='33vw'>
      <Header taskName={taskName} taskDescription={taskDescription} dueByMilestone={dueByMilestone} createdAt={createdAt} />
      
      <AdditionalInfo 
        taskDescription={taskDescription}
        portfolioUsers={portfolioUsers}
        assignmentsValue={assignmentsValue}
        handleReassignTask={handleReassignTask}
        blocking={blocking}
        process={process}
        user={user}
        isTaskFlow={isTaskFlow}
        isSubtask={isSubtask}
        deadlineAt={deadlineAt}
        dueByMilestone={dueByMilestone}
        updateTaskDate={updateTaskDate}
        updateTaskMilestone={updateTaskMilestone}
        readOnly={readOnly}
      />

      <TaskCommentsTimeline
        handleSubmit={handleSubmit}
        newComment={newComment}
        handleChange={handleChange}
        formError={formError}
        loading={loading}
        addToTimeline={addToTimeline}
        setAddToTimeline={setAddToTimeline}
        comments={comments}
        readOnly={readOnly}
        showEffect
      />

      <Bottom>
        <span>
          Enter your comment and hit <CustomIcon><MdKeyboardReturn /></CustomIcon> to post it. Press <span style={{display: 'inline-flex', alignItems: 'center'}}><CustomIcon sx={{mr: 0.5}}>SHIFT</CustomIcon><CustomIcon><MdKeyboardReturn /></CustomIcon></span> to start a new line.
        </span>
      </Bottom>
    </Drawer>
  )
}

export default TaskComments;