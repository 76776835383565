import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from "@apollo/client";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { rgba } from "emotion-rgba";
import Drawer from "../../styled/Drawer/Drawer";
import Typography from "../../styled/Typography/Typography";
import { Box, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Badge } from "@mui/material";
import Button from "../../styled/Button/Button";
import AddIcon from '@mui/icons-material/Add';
import Chip from '../../styled/Chip/Chip';
import { MessageContext } from '../../context/MessageContext';
import { GET_TASK_FLOW_TEMPLATES_QUERY } from '../../apollo/queries/getTaskFlowTemplatesQuery';
import { UserContext } from '../../context/UserContext';
import { UPDATE_TASK_FLOW_TEMPLATE_MUTATION } from '../../apollo/mutations/updateTaskFlowTemplateMutation';
import Checkbox from '../../styled/Checkbox/Checkbox';
import { JUDICIAL, NON_JUDICIAL, ALL_STATES, GENERIC_JUDICIAL, GENERIC_NONJUDICIAL } from '../../utilities/genericSteps';

const Header = styled(Box)`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({theme}) => rgba(theme.themeColor.bodyMain, 0.1)};
  height: 120px;
  color: ${({theme}) => theme.themeColor.bodySecondary};
  margin: 0 -25px;
  padding: 25px;
`

const Bottom = styled(Box)`
  display: flex;
  align-items: center;
  border-top: 1px solid ${({theme}) => rgba(theme.themeColor.bodyMain, 0.1)};
  height: 120px;
  background-color: ${({theme}) => theme.themeColor.sectionStroke};
  margin: 0 -25px;
  padding: 25px;
  position: absolute;
  bottom: 0;
  width: 100%;
  justify-content: space-around;
`

const EditTaskFlow = ({ taskFlow, show, setShow }) => {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [newTaskName, setNewTaskName] = useState("");
  const [createOnMilestone, setCreateOnMilestone] = useState(false);
  const { portfolioID } = useContext(UserContext);
  const [states, setStates] = useState([]);
  const [steps, setSteps] = useState([]);
  const [subtasks, setSubtasks] = useState(taskFlow.subtasks || []);

  useEffect(() => {
    if (show) {
      setOpen(show);
    } else setOpen(false);
    setFormData({
      ...taskFlow
    });
    setStates(taskFlow.states || []);
    setCreateOnMilestone(taskFlow.createOnMilestone);
  }, [taskFlow, show]);

  useEffect(() => {
    if (states.length > 0) {
      if (JUDICIAL.includes(states[0])) setSteps(GENERIC_JUDICIAL);
      else if (NON_JUDICIAL.includes(states[0])) setSteps(GENERIC_NONJUDICIAL);
    }
  }, [states]);

  const { addMessage } = useContext(MessageContext);
  const theme = useTheme();

  const [updateTaskFlow, { loading }] = useMutation(UPDATE_TASK_FLOW_TEMPLATE_MUTATION, {
    refetchQueries: [
      { query: GET_TASK_FLOW_TEMPLATES_QUERY, variables: { portfolioID } },
    ],
  });

  const onCancel = () => {
    setShow(false);
  }

  const handleChange = (event) => {
    const {name, value} = event.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const handleCheckbox = () => {
    setCreateOnMilestone(!createOnMilestone);
    setFormData((prevState) => ({
      ...prevState,
      createOnMilestone: !createOnMilestone,
    }));
  }

  const handleNewTaskNameChange = (event) => {
    const { value } = event.target;
    setNewTaskName(value);
  }

  const handleSubmit = async () => {
    try {
      const resp = await updateTaskFlow({
        variables: { input: {
          where: { id: formData.id },
          data: { 
            name: formData.name,
            description: formData.description,
            subtasks: subtasks,
            portfolioID,
            createOnMilestoneID: formData.createOnMilestoneID,
            createOnMilestone: formData.createOnMilestone,
            states
          } 
        }},
      });

      if ('errors' in resp) {
        addMessage({ message: "Something went wrong while updating the task.", type: "error"});
      } else {
        addMessage({ message: 'Task flow template updated.' });
        setShow(false);
      }

    } catch {
      addMessage({ message: 'Something went wrong. Try again later!', type: 'error' });
    }
  }

  const onAddNewSubTask = () => {
    if (!newTaskName) {
      addMessage({type: 'error', message: 'You need to set the subtask name.'});
    }
    const withAdded = [...subtasks, {
      name: newTaskName,
      order: subtasks.length + 1,
      responsible: '',
    }]
    setSubtasks(withAdded);

    setFormData((prevState) => ({
      ...prevState,
      subtasks: withAdded,
    }));
    setNewTaskName("");
  }

  const onDeleteTask = (taskToRemove) => {
    const copy = [...subtasks];
    const filtered = copy.filter(task => task.name !== taskToRemove.name);
    const updated = filtered.map((task, i) => ({
      ...task,
      order: i + 1
    }))

    setSubtasks(updated);
  }

  const onChangeStates = (event) => {
    const { value } = event.target;

    if (value[value.length - 1] === "all") {
      setStates(states.length === ALL_STATES.length ? [] : ALL_STATES);
      return;
    }
    setStates(typeof value === 'string' ? value.split(',') : value);
    setFormData((prev) => ({
      ...prev,
      states: states.length === ALL_STATES.length ? [] : ALL_STATES
    }))
  }

  const onChangeSelectedStep = (event) => {
    const { value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      createOnMilestoneID: value,
    }));
  }

  return (
    <Drawer open={open}>
      <Header theme={theme}>
        <Typography fontSize="24px">Update Task Flow Template</Typography>
      </Header>
      <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', height: '60vh', overflow: "hidden" }}>
        <FormControl sx={{mt:1}}>
          <TextField
            name="name"
            variant='outlined'
            label="Flow Name"
            onChange={handleChange}
            value={formData.name}
            id="name"
          />
          <TextField
            name="description"
            variant='outlined'
            label="Flow Description"
            onChange={handleChange}
            value={formData.description}
            id="name"
            sx={{ mt: '10px' }}
            multiline={5}
          />
          <Checkbox label="Create automatically when milestone is reached" checked={createOnMilestone} onChange={handleCheckbox} />
          {createOnMilestone && (
            <Stack spacing={2}>
              <FormControl>
                <InputLabel id="label-state-select">States</InputLabel>
                <Select
                  labelId="label-state-select"
                  label="State"
                  multiple
                  value={states}
                  onChange={onChangeStates}
                >
                  <MenuItem value="all" key="select-all-states">Select All</MenuItem>
                  {ALL_STATES.map((state) => (
                    <MenuItem key={state} value={state}>{state}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel id="label-milestone-select">Milestone</InputLabel>
                <Select
                  labelId="label-milestone-select"
                  label="Milestone"
                  onChange={onChangeSelectedStep}
                  value={formData.createOnMilestoneId}
                  disabled={steps.length === 0}
                >
                  {steps.map((step) => (
                    <MenuItem key={step.stepID} value={step.stepID}>{step.stepID} - {step.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          )}
          <Typography fontSize="20px" sx={{ mt: 2 }}>Subtasks</Typography>
          <Box sx={{ mt: 1, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <TextField
              name="name"
              variant='outlined'
              label="New subtask name"
              onChange={handleNewTaskNameChange}
              value={newTaskName}
              id="name"
              sx={{ mr: '10px' }}
            />
            <Button
              sx={{ width: '50px' }}
              onClick={onAddNewSubTask}
            >
              <AddIcon />
            </Button>
          </Box>
          <Stack direction="column" spacing={2} 
            sx={{ mt: 2, overflowY: "auto", 
              height: createOnMilestone ? "20vh" : "50vh",
              maxHeight: createOnMilestone ? "20vh" : "50vh",
              paddingBottom: createOnMilestone ? "100px" : "250px"
            }}
          >
            {subtasks.map((task, idx) => (
              <div key={`${task.name}-${idx}`}>
                <Badge sx={{ml: 1.5, mr: 3, fontFamily: 'IBM Plex Mono'}} badgeContent={idx + 1} color="secondary" />
                <Chip
                  sx={{ width: '75%', maxWidth: '75%' }}
                  key={idx}
                  label={task.name}
                  onDelete={() => onDeleteTask(task)}
                />
              </div>
            ))}
          </Stack>
        </FormControl>
      </Box>
      <Bottom>
        <Button
          onClick={handleSubmit}
          disabled={subtasks.length === 0 || loading}
          loading={loading}
          sx={{maxWidth: '120px'}}
        >
          Update
        </Button>
        <a onClick={onCancel}>Cancel</a>
      </Bottom>
    </Drawer>
  )
}

export default EditTaskFlow;